import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

export default ({ shouldMove = true }) => {
  const { t } = useLanguage()
  return (
    <FancyShow shouldMove={shouldMove}>
      <Container>
        <Row>
          <Col col={{ lg: 9 }} offset={{ lg: 2 }} className='pt-5'>
            <p className='parag-small font-italic'>{t`news page|footnote`}</p>
          </Col>
        </Row>
      </Container>
    </FancyShow>
  )
}
